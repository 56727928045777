










import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    infoAccess: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {};
  },
});
